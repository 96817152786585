// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import '@hotwired/turbo';
import './vendor/bootstrap';

import Popover from 'bootstrap/popover';
import Toast from 'bootstrap/toast';
import Tooltip from 'bootstrap/tooltip';
import SelectorEngine from 'bootstrap/dom/selector-engine';

/**
 * ------------------------------------------------------------------------
 * Main application
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  document.documentElement.classList.remove('no-js');

  // Initialize Popover, Tooltip and Toast components

  SelectorEngine.find('[data-bs-toggle="popover"]').forEach(
    (popover) => new Popover(popover)
  );

  SelectorEngine.find('[data-bs-toggle="tooltip"]').forEach(
    (tooltip) => new Tooltip(tooltip)
  );

  SelectorEngine.find('.toast').forEach((toastNode) => {
    const toast = new Toast(toastNode, {
      autohide: false,
    });

    toast.show();
  });
});
